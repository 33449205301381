import './CommunityPage.scss'
import React from 'react'
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ActiveCampaignForm from "../../components/ActiveCampaignForm"

const CommunityPage = () => (
    <Layout>
        <SEO title="Community"/>
        Community
        <ActiveCampaignForm/>
    </Layout>
);

export default CommunityPage
