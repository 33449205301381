import React from 'react'

class ActiveCampaignForm extends React.Component {
    componentDidMount() {
        const script = document.createElement('script');

        script.src = 'https://steevehook.activehosted.com/f/embed.php?id=1';
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        return (
            <div className="_form_1"/>
        )
    }
}

export default ActiveCampaignForm
